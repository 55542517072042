import colors from '../../assets/colors'

const checkoutDialogStyles = {
  totalPriceRowRoot: {
    borderTop: `1px solid ${colors.LightGrey}`
  },
  closeTableCellRoot: {
    width: 40
  }
}

export default checkoutDialogStyles