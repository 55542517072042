import { createMuiTheme } from '@material-ui/core/styles'
import colors from './assets/colors'

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.DarkGrey
    },
    secondary: {
      main: colors.Golden,
    },
  },
  typography: {
    fontFamily: "'Libre Baskerville', serif",
    fontSize: 12
  },
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: 4
      }
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 0px)': {
          minHeight: 64
        }
      }
    }
  }
})