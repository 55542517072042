import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { useAsyncFn } from 'react-use'

import api from '../../api/ApiClient'

// Actions
import { selectProduct, deselectProduct, setProductCategories } from '../../store/actions'

// Components
import CategoryProductsList from '../categoryProductList/CategoryProductsList'

// Material components
import { Box, Grid, Typography } from '@material-ui/core'

// Styles
import productsListStyles from './productsListStyles'

const fetchServerProducts = async () => {
  return await axios
    .get(process.env.REACT_APP_API_URL + '/product')
    .then((response) => {
      console.log(response)
      return response.data.content
    })
}

const ProductsList = (props) => {
  const {
    selectedProducts,
    setProductCategories
  } = props

  const [selected, setSelected] = useState([])


  const [
    { value: categories, loading: categoriesLoading },
    fetchCategories
  ] = useAsyncFn(() => api.get('/category', {}).then(response => response.content), [])

  useEffect(() => {
    console.log('TEST asd')
    fetchCategories()
  }, [])

  useEffect(() => {
    setSelected(selectedProducts)
  }, [selectedProducts, props.selectedProducts])

  useEffect(() => {
    setProductCategories(categories)
  }, [categories])

  return (
    <>
      <Grid
        container
        direction='row'
        justify='space-evenly'
        alignItems='center'
        spacing={0}
      >
        {
          categories && categories.map((category, index) => {
            return <Grid item xs={12} sm={12} md={10} lg={10} key={category.id + '' + index}>
              <Box mt={3}>
                <CategoryProductsList category={category}/>
              </Box>
            </Grid>
          })
        }
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.selectedProducts,
    products: state.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: (product) => dispatch(selectProduct(product)),
    deselectProduct: (product) => dispatch(deselectProduct(product)),
    setProductCategories: (categories) => dispatch(setProductCategories(categories))
  }
}

const ConnectedProductsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsList)

export default withStyles(productsListStyles)(ConnectedProductsList)
