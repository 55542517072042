import colors from '../../assets/colors'

const headerStyles = (theme) => {
  return {
    appBarOnTop: {
      background: 'transparent',
      boxShadow: 'none',
    },
    appBar: {
      background: colors.DarkGrey,
    },
    headerLogo: {
      height: 56,
      transition: 'height 0.3s ease-in',
    },
    headerLogoHide: {
      height: 0,
      transition: 'height 0.3s ease-in',
    },
    sectionMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    sectionDesktop: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    }
  }
}

export default headerStyles
