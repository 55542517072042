import colors from '../../assets/colors'

const footerStyles = {
  footerContainer: {
    // height: 320,
    display: 'block',
    marginTop: 50,
    backgroundColor: colors.DarkGrey,
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  footerLogo: {
    height: 256
  },
  stressedText: {
    fontWeight: 600
  }
}

export default footerStyles