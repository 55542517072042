import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

// Actions
import { setIsOpenCheckout, deselectProduct } from '../../store/actions'

// Material Components
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core'

// Icons
import { Close } from '@material-ui/icons'

// Styles
import checkoutDialogStyles from './checkoutDialogStyles'

const Checkout = ({
  selectedProducts,
  isOpen,
  setIsCheckoutOpen,
  deselectProduct,
  classes,
}) => {
  const [open, setOpen] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (isEmpty(selectedProducts)) {
      setIsCheckoutOpen(false)
    }
    setTotalPrice(selectedProducts.reduce((agg,item) => agg + item.price, 0))
  }, [selectedProducts, setIsCheckoutOpen])

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setIsCheckoutOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h5'>Products</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table aria-label='Checkout table'>
                    <TableBody>
                      {selectedProducts.map((p, i) => (
                        <TableRow key={i}>
                          <TableCell
                            classes={{
                              root: classes.closeTableCellRoot,
                            }}
                          >
                            <IconButton
                              size='small'
                              onClick={() => deselectProduct(p.id)}
                            >
                              {' '}
                              <Close />
                            </IconButton>
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            <Typography variant='h6'>{`${p.name} (${p.desc})`}</Typography>
                          </TableCell>
                          <TableCell align='right'>
                          <Typography variant='h6'>{p.price}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key={'bottom-row-checkout'}>
                        <TableCell align='right'></TableCell>
                        <TableCell align='right' scope='row'>
                          <Typography variant='h5'>Total: </Typography>
                        </TableCell>
                        <TableCell
                          align='right'
                          classes={{
                            root: classes.totalPriceRowRoot,
                          }}
                        >
                          <Typography variant='h5'>{totalPrice}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5'>Contact information</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='First name' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='Last name' />
                </Grid>
                <Grid item xs={12} >
                  <TextField variant='outlined' fullWidth label='Email' />
                </Grid>
                <Grid item xs={12} >
                  <TextField variant='outlined' fullWidth label='Phone number' />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h5'>Address</Typography>
                </Grid>
                <Grid item xs={12} >
                  <TextField variant='outlined' fullWidth label='Street address' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='City' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='State/Province' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='Postal code' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label='Country' />
                </Grid>
                <Grid item xs={12}>
                  <TextField variant='outlined' fullWidth label='Special instructions' multiline rows={4}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}><Typography variant='h5'>Payment method</Typography></Grid>         
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCheckoutOpen(false)}>Cancel</Button>
          <Button color='secondary' variant='contained'>
            Order
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.selectedProducts,
    isOpen: state.isCheckoutOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsCheckoutOpen: (isOpen) => dispatch(setIsOpenCheckout(isOpen)),
    deselectProduct: (product) => dispatch(deselectProduct(product)),
  }
}

const ConnectedCheckout = connect(mapStateToProps, mapDispatchToProps)(Checkout)

export default withStyles(checkoutDialogStyles)(ConnectedCheckout)
