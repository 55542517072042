import React from 'react'
import { withStyles } from '@material-ui/core/styles'

// Styles
import presentationStyles from './presentationStyles'

const Presentation = ({classes}) => {
  return (
    <div className={classes.presentationContainer}>
    </div>
  )
}

export default withStyles(presentationStyles)(Presentation)