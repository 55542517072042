import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

// Actions
import { setIsOpenCheckout } from '../../store/actions'

// Components
import Header from '../../components/header/Header'
import Presentation from '../../components/presentation/Presentation'
import ProductsList from '../../components/productsList/ProductsList'
import About from '../../components/about/About'
import Footer from '../../components/footer/Footer'
import CheckoutDialog from '../../components/checkout/CheckoutDialog'

// Material components
import { Box, Grid, Fab } from '@material-ui/core'

// Material Icons
import { ShoppingCart } from '@material-ui/icons'

// Styles
import landingStyles from './landingStyles'

const Landing = ({ classes, selectedProducts, setIsCheckoutOpen }) => {
  const [isVisibleFloatingButton, setIsVisibleFloatingButton] = useState(false)

  const onCheckoutClick = () => {
    setIsCheckoutOpen(true)
  }

  useEffect(() => {
    setIsVisibleFloatingButton(!!selectedProducts.length)
  }, [selectedProducts])

  return (
    <div className={classes.container}>
      <CheckoutDialog />
      {isVisibleFloatingButton ? (
        <Fab
          color='secondary'
          aria-label='Open shopping cart'
          className={classes.floatingButton}
          variant="extended"
          size="medium"
          onClick={onCheckoutClick}
        >
          <ShoppingCart />   
          Checkout
        </Fab>
      ) : (
        ''
      )}
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Presentation />
        </Grid>
        <Grid item xs={12} id='cakes'>
          <Box mt={4} mb={4}>
            <ProductsList />
          </Box>
        </Grid>
        <Grid item xs={12} id='about'>
          <About />
        </Grid>
        <Grid item xs={12} id='contacts'>
          <Footer />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.selectedProducts,
  }
}

const mapDispatchtoProps = (dispatch) => {
  return {
    setIsCheckoutOpen: (isOpen) => dispatch(setIsOpenCheckout(isOpen))
  }
}

const ConnectedLanding = connect(mapStateToProps, mapDispatchtoProps)(Landing)

export default withStyles(landingStyles)(ConnectedLanding)
