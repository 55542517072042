import axios from 'axios'

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000
}

class ApiClient {

  constructor() {
    this.instance = this.getInstance()
    this.instance.interceptors.request.use(this.onReqFulfilled)
  }

  getInstance() {
    if (!this.instance) {
      return axios.create(axiosConfig)
    }
    return this.instance
  }

  onReqFulfilled(config) {
    // const lang = localStorage.getItem('lang')
    // const token = localStorage.getItem('token')
    // config.headers['Accept-Language'] = lang || 'en'
    // config.headers.Authorization = token ? `Bearer ${token}` : ''

    return config
  }

  get(endpoint, opts) {
    return this.instance
      .get(endpoint, { ...opts })
      .then((response) => response.data)
  }

  post(endpoint, body, opts) {
    return this.instance.post(endpoint, body, { ...opts })
  }

  put(endpoint, body, opts) {
    return this.instance.put(endpoint, body, { ...opts })
  }

  delete(endpoint, opts) {
    return this.instance.delete(endpoint, { ...opts })
  }

  setResponseInterceptor(history, setAuth) {
    this.instance.interceptors.response.use(
      (res) => res,
      (error) => {
        const { response } = error

        if (response && response.status === 401) {
          // authProvider.logAuth({ history, setAuth })
        }
        return error
      }
    )
  }
}

export default new ApiClient()
