import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { useAsyncFn } from 'react-use'

import api from '../../api/ApiClient'

// Actions
import { selectProduct, deselectProduct } from '../../store/actions'

// Components
import Product from '../product/Product'

// Material components
import { Box, Grid, Typography } from '@material-ui/core'

// Styles
import categoryProductsListStyles from './categoryProductsListStyles'

const CategoryProductsList = (props) => {
  const {
    classes,
    products,
    selectedProducts,
    selectProduct,
    deselectProduct,
    category
  } = props

  const [selected, setSelected] = useState([])
  const [providedProducts, setProvidedProducts] = useState(products)

  const onProductSelect = (product) => {
    selectProduct(product)
  }

  const onProductDeselect = (product) => {
    deselectProduct(product)
  }

  const checkIfProductIsSelected = (product) => {
    for (let p of selected) {
      if (p.id === product.id) return true
    }
    return false
  }

  useEffect(() => {
    setSelected(selectedProducts)
  }, [selectedProducts, props.selectedProducts])

  const [
    { value: serverProducts, loading: productsLoading },
    fetchProducts
  ] = useAsyncFn(
    () => api.get(`/product?category.code=${category.code}`, {}).then((response) => response.content),
    []
  )

  useEffect(() => {
    if (serverProducts) setProvidedProducts([...products, ...serverProducts])
  }, [serverProducts, products])

  useEffect(() => {
    fetchProducts()
  }, [category])

  return (
    <>
      {' '}
      <Grid
        container
        direction='row'
        justify='space-evenly'
        spacing={0}
        className={classes.productsListContainer}
        id={category.title}
      >
        <Grid item xs={12}>
          <Box p={4}>
            <Typography variant='h4'>{category.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={4}>
            <Grid
              container
              direction='row'
              justify='space-evenly'
              alignItems='center'
              spacing={4}
            >
              {providedProducts.map((p, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
                  <Product
                    product={p}
                    onSelect={onProductSelect}
                    selected={checkIfProductIsSelected(p)}
                    onDeselect={onProductDeselect}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.selectedProducts,
    products: state.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: (product) => dispatch(selectProduct(product)),
    deselectProduct: (product) => dispatch(deselectProduct(product))
  }
}

const ConnectedCategoryProductsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryProductsList)

export default withStyles(categoryProductsListStyles)(ConnectedCategoryProductsList)
