import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

// Actions
import { selectProduct, deselectProduct } from '../../store/actions'

// Material components
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core'

// Material icons
import { AddShoppingCart, Close } from '@material-ui/icons'

// Styles
import productStyles from './productStyles'

const imagesBaseUrl = process.env.REACT_APP_MEDIA_API_URL

const Product = ({
  product,
  classes,
  selectProduct,
  deselectProduct,
  selected,
}) => {
  const { name } = product
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState('')

  useEffect(() => {
    if (product.variants && product.variants.length) {
      setPrice(product.variants[0].price)
      setQuantity(product.variants[0].quantity)
    }
  }, [product])

  const onProductDeselect = () => {
    deselectProduct(product.id)
  }

  const onProductSelect = () => {
    selectProduct({
      ...product,
      price,
      desc: `${quantity} pieces`,
    })
  }

  const handleVariantChange = (event) => {
    const quantity = event.target.value
    setQuantity(quantity)

    // const quantityPrice = product.variants.find((p) => p.quantity === quantity)
    //   .price
    // setPrice(quantityPrice)
  }

  return (
    <>
      <Card className={classes.root + ' ' + (selected ? 'selected' : '')}>
        {selected ? (
          <IconButton
            aria-label='add to favorites'
            classes={{ root: classes.closeIcon }}
            onClick={onProductDeselect}
          >
            <Close />
          </IconButton>
        ) : (
          ''
        )}

        <CardMedia
          className={classes.media}
          image={imagesBaseUrl + product.picture}
          title={product.name}
        />
        <CardHeader title={name} />
        <CardContent>
          <Typography variant='body2' color='textSecondary' component='p'>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            Repellendus architecto corporis ea ratione dolor expedita
            reiciendis, molestiae aliquam minima optio?
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Grid container alignItems='center'>
            <Grid item xs={5}>
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs={6}>
                  <Typography variant='h6' color='textSecondary' component='h6'>
                    Pieces
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    select
                    id='input-with-icon-grid'
                    value={quantity}
                    onChange={handleVariantChange}
                  >
                    {product.variants
                      .map((v) => v.quantity)
                      .map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs={6}>
                  <Typography variant='h6' color='textSecondary' component='h6'>
                    Price
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='h6' color='textSecondary' component='h6'>
                    ${price}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs={12}>
                  <IconButton
                    aria-label='Add to cart'
                    onClick={onProductSelect}
                  >
                    <AddShoppingCart />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: (product) => dispatch(selectProduct(product)),
    deselectProduct: (product) => dispatch(deselectProduct(product)),
  }
}

const ConnectedProduct = connect(null, mapDispatchToProps)(Product)

export default withStyles(productStyles)(ConnectedProduct)
