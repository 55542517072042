import ActionTypes from '../actionTypes'

export const setSelectedProducts = (payload) => {
  return {
    type: ActionTypes.SET_SELECTED_PRODUCTS,
    payload
  }
}

export const selectProduct = (payload) => {
  return {
    type: ActionTypes.SELECT_PRODUCT,
    payload
  }
}

export const deselectProduct = (payload) => {
  return {
    type: ActionTypes.DESELECT_PRODUCT,
    payload
  }
}

export const setIsOpenCheckout = (payload)=> {
  return {
    type: ActionTypes.SET_IS_CHECKOUT_OPEN,
    payload
  }
}

export const setProductCategories = (payload)=> {
  return {
    type: ActionTypes.SET_PRODUCT_CATEGORIES,
    payload
  }
}