const landingStyles = {
  container: {
    overflow: 'hidden',
    paddingBottom: 384,
    position: 'relative'
  },
  floatingButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 10
  }
}

export default landingStyles