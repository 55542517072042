import React from 'react'
import { withStyles } from '@material-ui/core/styles'

// Material Components
import {
  Grid,
  Typography
} from '@material-ui/core'

// Styles
import aboutStyles from './aboutStyles'

const About = ({classes}) => {
  return (
    <>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
            <Typography variant="h4"></Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.aboutImage}>
              
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.infoTextContainer}>
              <Typography variant="h3" align="left" paragraph color="primary">
                About chef Eliza
              </Typography>
              <Typography variant="h6" align="left" paragraph>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem tenetur, cumque nobis accusamus voluptate iste veniam sapiente reiciendis aliquam voluptatum eius nisi quae rerum officiis aut. Qui animi sit dignissimos. Sint repellat nesciunt eum adipisci obcaecati ab voluptates esse, autem impedit dolor eaque vel illo, dolorum ullam, sapiente qui repudiandae?
              </Typography>
              <Typography variant="h6" align="left" paragraph>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem tenetur, cumque nobis accusamus voluptate iste veniam sapiente reiciendis aliquam voluptatum eius nisi quae rerum officiis aut. Qui animi sit dignissimos. Sint repellat nesciunt eum adipisci obcaecati ab voluptates esse, autem impedit dolor eaque vel illo, dolorum ullam, sapiente qui repudiandae?
              </Typography>
              <Typography variant="h6" align="left" paragraph>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem tenetur, cumque nobis accusamus voluptate iste veniam sapiente reiciendis aliquam voluptatum eius nisi quae rerum officiis aut. Qui animi sit dignissimos. Sint repellat nesciunt eum adipisci obcaecati ab voluptates esse, autem impedit dolor eaque vel illo, dolorum ullam, sapiente qui repudiandae?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(aboutStyles)(About)