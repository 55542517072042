import chefImage from '../../assets/chef.jpg'


const presentationStyles = {
  aboutImage: {
    background: `url(${chefImage})`,
    backgroundSize: '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 500
  },
  infoTextContainer: {
    padding: 20
  }
}

export default presentationStyles