import React from 'react'
import { withStyles } from '@material-ui/core/styles'

// Images
import logoDarkGrey from '../../assets/logo-dark-grey.png'

// Material components
import { Box, Grid, Typography } from '@material-ui/core'

// Styles
import footerStyles from './footerStyles'

const Footer = ({ classes }) => {
  return (
    <>
      <div className={classes.footerContainer}>
        <Box p={4}>
          <Grid container justify='center'>
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Grid
                container
                direction='row'
                justify='space-evenly'
                spacing={8}
              >
                <Grid
                  item
                  container
                  direction='row'
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  justify='center'
                  alignItems='center'
                >
                  <img
                    src={logoDarkGrey}
                    alt='Eliza Bakery'
                    className={classes.footerLogo}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={2} lg={2} align='left'>
                <Typography variant='h4' align='left' color='secondary'>
                  Cakes
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
              </Grid> */}
                {/* <Grid item xs={12} sm={12} md={2} lg={2} align='left'>
                <Typography variant='h4' align='left' color='secondary'>
                  Cakes
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
                <Typography
                  variant='h6'
                  align='left'
                  paragraph
                  color='secondary'
                >
                  Lorem, ipsum dolor.
                </Typography>
              </Grid> */}
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      {' '}
                      <Typography
                        variant='h4'
                        align='left'
                        color='secondary'
                        paragraph
                      >
                        Contact Info
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant='h6'
                        align='left'
                        paragraph
                        color='secondary'
                      >
                        <span className={classes.stressedText}>Address:</span>{' '}
                        178 Varna Street, Bankya 1320, Bulgaria
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {' '}
                      <Typography
                        variant='h6'
                        align='left'
                        paragraph
                        color='secondary'
                      >
                        <span className={classes.stressedText}>
                          Phone number:
                        </span>{' '}
                        +359 887 451 408
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {' '}
                      <Typography
                        variant='h6'
                        align='left'
                        paragraph
                        color='secondary'
                      >
                        <span className={classes.stressedText}>Email:</span>{' '}
                        elisaveta.bandrov@yahoo.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  )
}

export default withStyles(footerStyles)(Footer)
