import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import store from './store'

// Pages
import Landing from './pages/Landing/Landing'


// Styles
import theme from './theme'

function App() {
  return (
    <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Landing />
      </ThemeProvider>
    </Provider>
    </>
  );
}

export default App;
