import initialStore from '../initialStore'
import ActionTypes from '../actionTypes'


const rootReducer = (state = initialStore, action) => {
  if (action.type === ActionTypes.SET_SELECTED_PRODUCTS) {
    return Object.assign({}, state, {
      selectedProducts: action.payload
    })
  } else if (action.type === ActionTypes.SELECT_PRODUCT) {
    state.selectedProducts.push(action.payload)
    return Object.assign({}, state, {
      selectedProducts: [...state.selectedProducts]
    })
  } else if (action.type === ActionTypes.DESELECT_PRODUCT) {
    const selectedProducts = state.selectedProducts
    const indexOfProduct = selectedProducts.findIndex(p => p.id === action.payload)
    selectedProducts.splice(indexOfProduct, 1)

    return Object.assign({}, state, {
      selectedProducts: [...selectedProducts]
    })
  } else if (action.type === ActionTypes.SET_IS_CHECKOUT_OPEN) {
    return Object.assign({}, state, {
      isCheckoutOpen: action.payload
    })
  } else if (action.type === ActionTypes.SET_PRODUCT_CATEGORIES) {
    return Object.assign({}, state, {
      productCategories: action.payload
    })
  }

  return state
}

export default rootReducer