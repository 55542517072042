import presentationImage from '../../assets/presentation.png'


const presentationStyles = {
  presentationContainer: {
    background: `url(${presentationImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh'
  }
}

export default presentationStyles