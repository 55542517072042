import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'

// Images
import logoDarkGrey from '../../assets/logo-dark-grey.png'

// Material Icons
import { Menu as MenuIcon } from '@material-ui/icons'

// Material components
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core'

// Styles
import headerStyles from './headerStyles'

const Header = ({ classes, productCategories }) => {
  const [showToolbar, setShowToolbar] = useState(false)
  const [categories, setCategories] = useState([])
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const onScroll = () => {
    if (window.pageYOffset === 0) {
      setShowToolbar(false)
    } else {
      setShowToolbar(true)
    }
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  useEffect(() => {
    setCategories(productCategories)
  }, [productCategories])

  useEffect(() => {
    console.log('Use effect')
    window.onscroll = onScroll
  })

  const mobileMenuId = 'primary-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {categories &&
        categories.map((category) => (
          <MenuItem>
            <Button href={`#${category.title}`} color='primary'>
              {category.title}
            </Button>
          </MenuItem>
        ))}

      <MenuItem>
        <Button href='#about' color='primary'>
          About
        </Button>
      </MenuItem>
      <MenuItem>
        <Button href='#contacts' color='primary'>
          Contacts
        </Button>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <AppBar
        position='fixed'
        className={showToolbar ? classes.AppBar : classes.appBarOnTop}
      >
        <Toolbar>
          <Grid container alignItems='center'>
            <Grid item xs={5}></Grid>
            <Grid item container xs={2} justify='center'>
              <img
                src={logoDarkGrey}
                alt='Eliza Bakery'
                className={
                  showToolbar ? classes.headerLogo : classes.headerLogoHide
                }
              />
            </Grid>
            <Grid
              item
              container
              className={classes.sectionDesktop}
              xs={5}
              justify='flex-end'
            >
              {categories &&
                categories.map((category, index) => (
                  <Button href={`#${category.title}`} color='secondary' key={category.id + '' + index}>
                    {category.title}
                  </Button>
                ))}
              <Button href='#about' color='secondary'>
                About
              </Button>
              <Button href='#contacts' color='secondary'>
                Contacts
              </Button>
            </Grid>
            <Grid
              item
              container
              className={classes.sectionMobile}
              xs={5}
              justify='flex-end'
            >
              <IconButton
                className={classes.sectionMobile}
                onClick={handleMobileMenuOpen}
                color='secondary'
              >
                {' '}
                <MenuIcon />
              </IconButton>
              {renderMobileMenu}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    productCategories: state.productCategories
  }
}

const ConnectedHeader = connect(mapStateToProps)(Header)

export default withStyles(headerStyles)(ConnectedHeader)
