import colors from '../../assets/colors'

const productStyles = {
  media: {
    height: 285,
    width: '100%'
  },
  root: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: `0px 0px 20px ${colors.LightGrey}`,
    },
    '&.selected': {
      boxShadow: `0px 0px 25px ${colors.Golden}`,
    }
  },
  closeIcon: {
    borderRadius: 30,
    backgroundColor: colors.DarkGrey,
    color: colors.LightGrey,
    height: 30,
    width: 30,
    position: 'absolute',
    top: 5,
    right: 5,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& :hover': {
      borderRadius: 30,
      height: 30,
      width: 30,
      fontSize: '1.28rem',
      backgroundColor: colors.LightGrey,
      color: colors.DarkGrey,
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      padding: 6,
      boxSizing: 'border-box',
      cursor: 'pointer'
    }
  }
}

export default productStyles