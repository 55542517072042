import colors from '../../assets/colors'

const productsListStyles = {
  productsListContainer: {
    borderRadius: '4px',
    boxShadow: `0px 0px 20px ${colors.LightGrey}`,
    // margin: 20
  }
}

export default productsListStyles